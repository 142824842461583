import React, { useState, useEffect } from "react";

import Chart from "react-apexcharts";

import moment from "moment";

import { LoadingScreen } from "../../../components/LoadingScreen";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import MonitoreoOTD from "../../../socket/MonitoreoOTD";
import CollapsibleTable from "./components/tableByVertical.jsx"
import { CSVLink } from "react-csv";
import { Dimmer, Button, Icon ,Table,List } from "tabler-react";
import ModalLayout from "../../../components/ModalLayout.react.js";
import TableByTransportistas from "./components/tableByTransportistas.jsx";






const ContentOtd = () => {
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais
  const [otdList, setOtdList] = useState([]);
  const [flagUniversal,setFlagUniversal] = useState(false);
  const [otdListFiltrados, setotdListFiltrados] = useState([]);
  const [otdListFiltradosTemp, setotdListFiltradosTemp] = useState([]);
  const [otdEstatusOpciones, setOtdEstatusOpciones]= useState([]);
  const [otdEstatusOpcion, setOtdEstatusOpcion] = useState();
  const [verticalOpciones, setverticalOpciones]= useState([]);
  const [verticalOpcion, setverticalOpcion] = useState();
  const [lineaTransportistaOpciones, setLineaTransportistasOpciones]= useState([]);
  const [otdListFiltradosTransportistas, setotdListFiltradosTransportistas] = useState([]);
  const [arrayFilters,setArrayFilters] = useState([{}]);
  //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(true);
  const [hiddenModals,setHiddenModals] = useState(false);
  const [loadingEntregas,setLoadingEntregas] = useState(false);
  
  // Obtiene  mes, y dia  del año actual
  let dateNow = new Date();
  let datanew = [];
  let startDate = `${dateNow.getFullYear()}-01-01`;
  
  const year = dateNow.toLocaleString("default", { year: "numeric" });
  const month = dateNow.toLocaleString("default", { month: "2-digit" });
  const day = dateNow.toLocaleString("default", { day: "2-digit" });

  // Genera yyyy-mm-dd date string
  let endDate = year + "-" + month + "-" + day;
  let id = JSON.parse(sessionStorage.getItem("plataforma")).id;

  const time = new Date();
  const topic = "/topic/Maersk-OTD"

 
  time.setHours(12);
  time.setMinutes(0);
  time.setSeconds(0);
  

  useEffect(() => {
    getfuntion("get", {"startDate":startDate,"endDate":endDate}, "", "movimientosOTD/otdsGps/" + id + "/" + startDate + "/" + endDate);
  }, []);

  const [dates, setDates] = useState([
    {
      startDate: moment(endDate).toDate(),
      endDate: moment(startDate).toDate(),
      key: 'selection'
    }
  ]);


  const formatDate = (date)=>{

    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Genera yyyy-mm-dd  string
    return year + "-" + month + "-" + day;

  }

  //Funcion de cambio en seleccion de fechas

  const handleDates = (range)=>{

  setisLoading(true);

  let startDate = formatDate(range.selection.startDate);

  let endDate = formatDate(range.selection.endDate)

  setDates([range.selection]);

  getfuntion("get",{"starDate":startDate,"endDate":endDate},"", "movimientosOTD/otdsGps/" + id +"/" + startDate + "/" +endDate);
    

  }


  const updateList = (newMovs)=>{
    
    const newMovs2 =  [...newMovs];

    filtroDeFiltros(newMovs2)
    filtro(newMovs2)
    setOtdList(newMovs2);
    
    

  }

  

  
 //Se realiza la consulta la primera vez.
  useEffect(() => {
    filtro()
  }, 
    [ 
      
      otdList,
      
    ]);

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      endPoint,
      "",
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal) {
               
                filtroDeFiltros(returnVal);
                filtro(returnVal);
                setOtdList(returnVal);
                
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };
  const filtroDeFiltros=(dataOtdLisTemp)=>{
    
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];
    let otdEstatus = {};
    let vertical = {};
    let lineaTransportista={};
    
    otdTemp.map((otd, index) => {
  

      //|otd_estatus
      if (!otdEstatus[otd?.status]) otdEstatus[otd?.status] = [];
        otdEstatus[otd?.status].push(otd);

      //line transportista
      if(otd){
        if (!lineaTransportista[otd["idTransportista"]]) lineaTransportista[otd["idTransportista"]] = [];
        lineaTransportista[otd["idTransportista"]].push(otd);
      }
     
      //vertical
      if(otd){
        if (!vertical[otd["vertical"]]) vertical[otd["vertical"]] = [];
        vertical[otd["vertical"]].push(otd);
      }
      
    });

    //creando headers
    let otdEstatusOpcionesTemp = [];
   
    let lineaTransportistasOpcionesTemp = [];
    let verticalOpcionesTemp = [];

    Object.keys(vertical) .forEach((key) => verticalOpcionesTemp.push({ value: key, label: key}));
    Object.keys(lineaTransportista) .forEach((key) => lineaTransportistasOpcionesTemp.push({ value: key, label: key}));
    Object.keys(otdEstatus)         .forEach((key) => otdEstatusOpcionesTemp.push({ value: key, label: key}));

   
    setLineaTransportistasOpciones(lineaTransportistasOpcionesTemp);
    setverticalOpciones(verticalOpcionesTemp)
    setOtdEstatusOpciones(otdEstatusOpcionesTemp);
  
  }

const handleChangeOptions = (event=null,data=null)=>{

    let arrayFiltersTemp;
    let selectOption;



    if((event&& event.action === "select-option")){
      selectOption = event.name;
      arrayFiltersTemp = [...arrayFilters,{"name": selectOption,"value": event.option.value}];
    }

    if((event && event.action === "remove-value")){
      arrayFiltersTemp = arrayFilters;
      let index = arrayFilters.findIndex((item)=> item.value === event.removedValue.value)
      if(index > 0)arrayFiltersTemp.splice(index, 1);

    }

    if(!arrayFiltersTemp){
      arrayFiltersTemp = [...arrayFilters]
    }

    
    let otdTemp  = data ? data: [...otdList];
    

    arrayFiltersTemp.forEach((filter)=>{

      {switch(filter.name){
      
        case "cliente":{
        
            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
             
                if(opcion.value === o.cliente ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
            console.log("sale cliente",otdTemp)
           break; 
        }
        case "estatus":{
      

            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
                if(opcion.value === o.status ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
         
          break;
        }
          
        case "corredor":{
         
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.corredor ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
          break;
        }
                
        case "movimiento":{
          console.log("Movimiento")
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.tipoMovimiento ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }

        case "idTransportista":{
          console.log("transportista")
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            console.log(o.tipoMovimiento)
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.idTransportista ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }
      }
    }

    })
    

    setArrayFilters(arrayFiltersTemp)
    setotdListFiltrados(otdTemp)
  

}

  const filtro=(dataOtdLisTemp)=>{
    
    console.log(dataOtdLisTemp)
    
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];
    let opciones = [...verticalOpciones]
    let transpostistasOpciones =  [...lineaTransportistaOpciones]
    let data = [];
    let dataTransportistas = [];
   
    let total = otdTemp.length;
    for( let p of opciones ){

   
    let structurDataOTD = {
        "vertical":null,
        "transportista":null,
        "clientes":[],
        "delay":0,
        "ontime":0,
        "totalMov":0,
        "sin_calcular":0,
        "porcent":100,
        "history":[]
  
    }
      
       let dataOtd =  (otdTemp.filter( g => {

     
        if(g.vertical === p.label && g.vertical!= null){
          structurDataOTD.vertical = p.label;
          structurDataOTD.transportista = g.idTransportista;
          structurDataOTD.totalMov += 1;
          if(!structurDataOTD.clientes.includes(g.cliente)){
            structurDataOTD.clientes.push(g.cliente);
          }
          
          if(g.status == "DELAY") { 
            
            structurDataOTD.delay += 1;
           
           }
          if(g.status == "ONTIME"){
            structurDataOTD.ontime += 1;
            
          } 
          if(g.status == "SIN_CALCULAR") {
            structurDataOTD.sin_calcular += 1;
          }

          console.log(g)

          structurDataOTD.history.push(g)
          
         
        }
      }));
      
      data.push(structurDataOTD);
      
     
    }


    for( let p of transpostistasOpciones ){

   
      let structurDataOTDTransportista = {
         "transportista":null,
          "clientes":[],
          "delay":0,
          "ontime":0,
          "totalMov":0,
          "sin_calcular":0,
          "porcent":100,
          "history":[]
    
      }
        
        let dataOtdTrans =   (otdTemp.filter( g => {
  
       
          if(g.idTransportista === p.label && g.idTransportista!= null){
            structurDataOTDTransportista.transportista = g.idTransportista;
            structurDataOTDTransportista.totalMov += 1;
            if(!structurDataOTDTransportista.clientes.includes(g.cliente)){
              structurDataOTDTransportista.clientes.push(g.cliente);
            }
            
            if(g.status == "DELAY") { 
              
              structurDataOTDTransportista.delay += 1;
             
             }
            if(g.status == "ONTIME"){
              structurDataOTDTransportista.ontime += 1;
              
            } 
            if(g.status == "SIN_CALCULAR") {
              structurDataOTDTransportista.sin_calcular += 1;
            }
  
            structurDataOTDTransportista.history.push(g)
            
           
          }
        }));
        
        dataTransportistas.push(structurDataOTDTransportista);
        
       
      }

 

   for (let index = 0; index < data.length; index++) {

    data[index].porcent = (100 * data[index].ontime) / data[index].totalMov;
      

    if(data[index].vertical == undefined){
      data.splice(index,1)
    }
    
   }


   for (let index = 0; index < dataTransportistas.length; index++) {

    dataTransportistas[index].porcent = (100 * dataTransportistas[index].ontime) / dataTransportistas[index].totalMov;
      

    console.log(dataTransportistas[index].porcent)
    console.log(dataTransportistas[index].transportista)

    if(dataTransportistas[index].transportista == undefined){
      dataTransportistas.splice(index,1)
    }
    
   }

   console.log(dataTransportistas)

    setotdListFiltrados(data);
    setotdListFiltradosTransportistas(dataTransportistas);
    //return otdTemp;
  }
  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  const getGraficaOtdEstatus = () => {
    let otdTemp= [...otdList];
    let labels = [];
    let data = new Array();
    let index = 0;

    
    for( let gEstatus of otdEstatusOpciones ){
      labels.push(gEstatus.label);
    
      data.push(otdTemp.filter( g => g.status === gEstatus.label).length);
      
    }



    

   
    return { labels, data };
  }
  const hiddenmodal = () => {
    setHiddenModals(!hiddenModals );
  };
  function BitacoraView ()
 {
  return (
    <div >
          <ModalLayout
            tamanio={"1200px"}
            title={"Eta por Transportista"}
            name={<Button pill size="sm" outline color="primary" 
            ><i name="list"  className="fa fa-truck "/>{"Transportista"}</Button>}
            hiddenModal={hiddenModals} Fclose={hiddenmodal}
            formulario={
              <Dimmer active={loadingEntregas} loader>
                 {otdListFiltradosTransportistas.length > 0 ?
          <TableByTransportistas data={otdListFiltradosTransportistas} heads={["Linea de transporte","Retrasados","En tiempo","Total",""]} ></TableByTransportistas>
         : null }
          { otdList.length ?
            <MonitoreoOTD
              topic={topic}
              otd={otdList}
              movsUpdate={updateList}
            />
       : null}
              </Dimmer>                                    
            }
          />
        </div>
        )

 }
 
  

  return (
    <div className="container-fluit">
      <div className="row justify-content-center" style={{ margin: "16px" }}>
        <h1>OTD LIVE </h1>
      
      </div>

      
      { otdListFiltrados.length &&
        <div className=" row--grafs--panel">
          <div className="col-12 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Estatus
                </h4>
              </div>
              <div className="card-body text-success">
                { otdListFiltrados.length ?
                  <Chart
                    series= {getGraficaOtdEstatus().data}
                  options= {{
                    chart: {
                      width: 3000,
                      type: 'donut',
                      animations:{
                        enabled:false
                      },
                    },
                    labels:getGraficaOtdEstatus().labels,
                    plotOptions: {
                      pie: {
                        startAngle: -90,
                        endAngle: 270
                      }
                    },  
                    animations:{
                      enabled:false
                    },            
                    dataLabels: {
                      enabled: true
                    },
                    fill: {
                      type: 'gradient',
                    },
                    legend: {
                      formatter: function(val, opts) {
                        return getGraficaOtdEstatus().labels[opts.seriesIndex]+" - "+ opts.w.globals.seriesTotals[opts.seriesIndex]
                      }
                    },
                    title: {
                      text: 'Estados del Movimiento'
                    }
                  }}
                    type="donut" width={"100%"} height={"auto"}/>
                :null}
              </div>
            </div>
          </div>
          
        </div>
      }
      <hr />
      <div className="row otd-container" hidden={!otdListFiltrados.length}>

        <div className="col-12 table-collapsed">
          
          {([...otdListFiltrados.map((item,i)=>{
             item.history.map((data) => {
              let newObject = {"Booking":data.booking,"Contenedor":data.contenedor,"Cita Programda":data.citaProgramada,
                               "Cliente":data.cliente, "Transportista":data.idTransportista,"ETA":data.etaCalculadas.eta
               }   
               datanew.push(newObject)
             })
           })])}

          <div className="row">

            <div className="col-3">
              {<CSVLink data={ datanew
             
            } filename={"dataOTDLIVE.csv"} >

           <i class="fa fa-file-excel-o text-success" > Descarga csv</i>
          
          </CSVLink> }
            </div>
          

          <div className="col-3">
          {<BitacoraView></BitacoraView>}

          </div>
          
          </div>
            
          {otdListFiltrados.length > 0 ?
          <CollapsibleTable data={otdListFiltrados} heads={["Vertical","Retrasados","En tiempo","Total",""]} ></CollapsibleTable>
         : null }
          { otdList.length ?
            <MonitoreoOTD
              topic={topic}
              otd={otdList}
              movsUpdate={updateList}
            />
       : null}     
        </div>
      </div>
      {isloading ? (
				<LoadingScreen
					loading={isloading}
				/>
			):null}
    </div>

    
  );
};

export default ContentOtd;