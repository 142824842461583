import React, { useState, useEffect } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import { ExportCSV } from "../../herramientas/Excel/ExportCSV";
import Call_Otds from "./Dashboards/DashboardOtds/Call_Otds";
import { DateRange, DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";

import { LoadingScreen } from "../../components/LoadingScreen";
import { each } from "jquery";


const MovDownLoadHellmann = () => {

  //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(true);
  const [lastTotal, setLastTotal] = useState(0);
  const [otdListFiltrados, setotdListFiltrados] = useState([]);
  const plataforma = JSON.parse(sessionStorage.getItem("plataforma"));
  
  // Obtiene  mes, y dia  del año actual
  let dateNow = new Date();

  
  const year = dateNow.toLocaleString("default", { year: "numeric" });
  const month = dateNow.toLocaleString("default", { month: "2-digit" });
  const day = dateNow.toLocaleString("default", { day: "2-digit" });

  // Genera yyyy-mm-dd date string
  let startDate =  year + "-" + month + "-" + day;
  const endDate = year + "-" + month + "-" + day;

  useEffect(() => {
    getfuntion("get",{"startDate":startDate,"endDate":endDate},"", "movimientosOTD/hellman" );
  }, []);


  const [dates, setDates] = useState([
    {
      startDate: endDate,
      endDate: startDate,
      key: 'selection'
    }
  ]);


  const formatDate = (date)=>{

    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Genera yyyy-mm-dd  string
    return year + "-" + month + "-" + day;

  }

  //Funcion de cambio en seleccion de fechas

  const handleDates = (range)=>{

    setisLoading(true);
  
    let startDate = formatDate(range.selection.startDate);
  
    let endDate = formatDate(range.selection.endDate)
  
    setDates([range.selection]);
  
    //getfuntion("get",{"starDate":startDate,"endDate":endDate},"", "movimientosOTDPerfil/usuario/" + plataforma.id);

    getfuntion("get",{"startDate":startDate,"endDate":endDate},"", "movimientosOTD/hellman" );
      
  
  }

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    stateVar = "",
    hiddenModl = ""
  ) => {
    Call_Otds(
      endPoint,
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal) {

                
                filtro(returnVal);
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };

  const handleClick = (flag)=>{
    console.log(flag)
    setLastTotal(lastTotal - otdListFiltrados.length)
  }

  const filtro=(dataOtdLisTemp)=>{
    let arrayList = [];

    dataOtdLisTemp.forEach((element,i) => {
        
          let CAT = ``;
          switch(true){
          
            case element.idTransportista.includes("LOGISTICA MARU"):{
              CAT = "10Q4";
              break; 
            }
            case element.idTransportista.includes("TRANSPORTES MORRIS DE PRAL"):{
              CAT = "36CJ";
            
              break;
            }

            case element.idTransportista.includes("TRASLADOS Y LOGISTICA MEXICANA 3T"):{
              CAT = "377C";
            
              break;
            }
              
            case element.idTransportista.includes("MXALT MULTIENLACES LOGÍSTICOS TERRESTRES"):{
              CAT = "16MA";
              break;
            }
                    
            case element.idTransportista.includes("MXVER AUTO EXPRESS FRONTERA NORTE"):{
              CAT = "0163";
              break;
            }
           
            case element.idTransportista.includes("TRANSPORTES MONTERREY"):{
              CAT = "11XW";
            break;
            }

          }

          let InfoPickUpImportacion = [];
          let InfoPickUpExportacion = [];
          

          
            switch(true){
          
              case element.corredor.includes("MANZANILLO"):{
                InfoPickUpImportacion.push("Av. Teniente Azueta No. 9 Col. Burocrata");
                InfoPickUpImportacion.push("Manzanillo");
                InfoPickUpImportacion.push("Colima");
                InfoPickUpImportacion.push("México");
                InfoPickUpImportacion.push("28250");
      
                break; 
              }
              case element.corredor.includes("VERACRUZ"):{
                InfoPickUpImportacion.push("Av. Marina Mercante No. 210 7 piso. Col Centro");
                InfoPickUpImportacion.push("Veracruz");
                InfoPickUpImportacion.push("Veracruz");
                InfoPickUpImportacion.push("México");
                InfoPickUpImportacion.push("91700");
              
                break;
              }
                
              case element.corredor.includes("LAZARO CARDENAS"):{
                InfoPickUpImportacion.push("Recinto Portuario");
                InfoPickUpImportacion.push("Lázaro Cárdenas");
                InfoPickUpImportacion.push("Michoacan");
                InfoPickUpImportacion.push("México");
                InfoPickUpImportacion.push("60950");
                break;
              }
                      
              case element.corredor.includes("ALTAMIRA"):{
                InfoPickUpImportacion.push("Puerto Industrial Altamira");
                InfoPickUpImportacion.push("Altamira");
                InfoPickUpImportacion.push("Tamaulipas");
                InfoPickUpImportacion.push("México");
                InfoPickUpImportacion.push("89603");
                break;
              }

              case element.corredor.includes("KANSAS MTY"):{
                InfoPickUpImportacion.push("Interpuerto Monterrey");
                InfoPickUpImportacion.push("MOnterrey");
                InfoPickUpImportacion.push("Nuevo León");
                InfoPickUpImportacion.push("México");
                InfoPickUpImportacion.push("");
                break;
              }
            
            }

            switch(true){
              case element.cliente.includes("HELLMANN WHIRLPOOL MEXICO"):{
                InfoPickUpExportacion.push("");
                InfoPickUpExportacion.push("");
                InfoPickUpExportacion.push("Ciudad de México");
                InfoPickUpExportacion.push("México");
                InfoPickUpExportacion.push("");
      
                break; 
              }
              case element.cliente.includes("HELLMANN WHIRLPOOL CELAYA"):{
                InfoPickUpExportacion.push("Av. Santa Barbara 121 A, Predio La Providencia");
                InfoPickUpExportacion.push("Celaya");
                InfoPickUpExportacion.push("Guanajuato");
                InfoPickUpExportacion.push("México");
                InfoPickUpExportacion.push("38020");
              
                break;
              }
                
              case element.cliente.includes("HELLMANN WHIRLPOOL RDC MEXICO"):{
                InfoPickUpExportacion.push("Antiguo Camino a la Lumbrera s/n Bodega Mezquite 1, Col. San Martín Obispo");
                InfoPickUpExportacion.push("Cuautitlán Izcalli");
                InfoPickUpExportacion.push("Estado de México");
                InfoPickUpExportacion.push("México");
                InfoPickUpExportacion.push("54763");
                break;
              }
                      
              case element.cliente.includes("HELLMANN WHIRLPOOL RDC NORTE"):{
                InfoPickUpExportacion.push("Prologis Park Apodaca No. 9. Carretera Miguel Alemán Km. 21");
                InfoPickUpExportacion.push("Apodaca");
                InfoPickUpExportacion.push("Nuevo León");
                InfoPickUpExportacion.push("México");
                InfoPickUpExportacion.push("66600");
                break;
              }
                
            
            }

          
          

          const wo = {
            SCAC: CAT,
            ShipmentIdentifier: element.booking +"-" +element.contenedor,
            TruckNumber : element.movimientosPatio[0]?.tractorPlaca ? element.movimientosPatio[0].tractorPlaca : "",
            DriverMobileNumber: element.movimientosPatio[0]?.operadorContacto ? element.movimientosPatio[0].operadorContacto : null,
            PickUpStopName:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ? "Puerto " + element.corredor : element.direccion,
            PickUpAddressLine:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpImportacion[0]: InfoPickUpExportacion[0],
            PickUpCity:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpImportacion[1]: InfoPickUpExportacion[1],
            PickUpState:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpImportacion[2]: InfoPickUpExportacion[2],
            PuckUpCountry:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpImportacion[3]: InfoPickUpExportacion[3],
            PickUpPostalCode:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpImportacion[4]: InfoPickUpExportacion[4],
            PickUpAppoinmentStartDate:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   element.arriboTerminalVacio : element.arriboClienteVacio,
            PickUpAppoinmentEndDate:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   element.salidaTerminalLleno : element.salidaClienteLleno,
            FinalDestinationStopName: element.tipoMovimiento == 1 ? element.destino : element.direccion,
            PickUpAppointmentDateTime: (element.tipoMovimiento  == 1 || element.tipoMovimiento == 336)  ?   element.arriboTerminalVacio : element.arriboClienteVacio,
            FinalDestinationAddressLine:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpExportacion[0]: InfoPickUpImportacion[0],
            FinalDestinationCity:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpExportacion[1]: InfoPickUpImportacion[1],
            FinalDestinationState:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpExportacion[2]: InfoPickUpImportacion[2],
            FinalDestinationCountry:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpExportacion[3]: InfoPickUpImportacion[3],
            FinalDestinationPostalCode:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   InfoPickUpExportacion[4]: InfoPickUpImportacion[4],
            
            DeliveryAppoinmentStartDate:(element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   element.arriboClienteLLeno : element.arriboTerminalCargado,
            DeliveryAppoinmentEndDate: (element.tipoMovimiento  == 1 || element.tipoMovimiento == 336) ?   element.salidaClienteVacio : element.salidaTerminalVacio,
            //Imei:  element.movGps[0]?.gps.imei,
          // DeliveryAppointmentDateTime:element.tipoMovimiento == 1 ? element.arriboClienteLLeno : element.arriboTerminalCargado,
            CompanyName: element.idTransportista,
            //ScheduledAppointment:element.citaProgramada

      
          };

          arrayList.push(wo)
        
      
    });

    console.log(arrayList)

    setotdListFiltrados(arrayList);
    setLastTotal(arrayList.length)
    //return otdTemp;
  }

	return (
		<SiteWrapper>
      <div className="container otd-container d-flex justify-content-center">
				<div className="container-fluit">
          <div className="row justify-content-center" style={{ margin: "16px" }}>
            <h2>Descarga Movimientos Whirlpool - Hellmann</h2>
          </div>

          <div className="row row--filters" >
            <div class="filter col-12">
              <div className="col-12 d-flex align-items-center flex-column">
                <div className="row">
                  <div className="col-12">
                  <DateRange
                    startDatePlaceholder={startDate}
                    endDatePlaceholder={endDate}
                    dateDisplayFormat={"yyyy-MM-dd"}
                    editableDateInputs={true}
                    dragSelectionEnabled={true}
                    showDateDisplay={true}
                    locale={es}
                    showSelectionPreview={true}
                    onChange={handleDates}
                    months={1}
                    ranges={dates}
                    direction="horizontal"
                  />
                  </div>
                </div>
                <div className="row">
                    <span>
                      <h5 className="text-center">Total {otdListFiltrados.length} movimientos a descargar</h5>
                    </span>
                </div> 
                {/* <div className="row">
                    {lastTotal != otdListFiltrados.length ? 
                    <span>
                      <h5 className="text-center">Nuevos movimientos desde la ultima descarga {lastTotal}</h5>
                    </span>
                    : null}
                </div>  */}
                <div className="row">
                  <div className="col-12">
                      

                  <div><ExportCSV csvData={otdListFiltrados} fileName={"movimientosMaerks"} title={"Descarga de Movimientos"} />{ }</div>
                      
                  
                </div>
                </div>
              </div>  
              
            </div>
                  
          </div>
        </div>
        {isloading ? (
				<LoadingScreen
					loading={isloading}
				/>
			):null}
      </div>
			
		</SiteWrapper>
	);
};

export default MovDownLoadHellmann;